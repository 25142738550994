import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import CleanTestedStructuredIcon from './images/clean-tested-structured.inline.svg';
import CustomizableIcon from './images/customizable.inline.svg';
import PrivacyPreservingDataIcon from './images/privacy-preserving-data.inline.svg';

const title = 'Optimize performance and data science without sacrificing privacy or quality';
const items = [
  {
    icon: PrivacyPreservingDataIcon,
    title: 'Privacy Preserving Data',
    description:
      'Extract the predictive signal needed to enhance model performance without exposing any personal information',
  },
  {
    icon: CleanTestedStructuredIcon,
    title: 'Clean, Tested, Structured',
    description:
      'Deploy models faster by working with behavioral data pre-processed and validated by Yobi’s team of data scientists',
  },
  {
    icon: CustomizableIcon,
    title: 'Customizable',
    description:
      'Build your own models using Yobi’s predictive signals or integrate pre-built predictive models by Yobi’s team',
  },
];

const Features = () => (
  <section className="mt-20 md:mt-40">
    <Container className="text-center">
      <Heading className="text-center" tag="h2" size="lg">
        {title}
      </Heading>

      <div className="grid gap-8 mt-16 lg:grid-cols-3">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon title={title} description={description} icon={Icon} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default Features;
