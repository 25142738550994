import React from 'react';

import animationData from 'animations/personalization/profile/diagram.json';
import Scheme from 'components/shared/scheme';

const title =
  'Integrate Yobi’s representations of behavior to expand your feature set and enhance modeling capabilities ';

const Profile = () => (
  <Scheme className="mt-32 md:mt-40" title={title} animationData={animationData} />
);

export default Profile;
