import React from 'react';

import Features from 'components/pages/personalization/features';
import Profile from 'components/pages/personalization/profile';
import RequestDemoHero from 'components/shared/request-demo-hero';
import UseCases from 'components/shared/use-cases';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const pageTitle = 'Personalization';
const pageDescription =
  'Build models that dynamically represent customer behavior to increase addressability and engagement';

const useCases = {
  title: 'Use cases',
  items: [
    {
      title: 'Advertising Targeting',
      features: [
        'Never miss an advertising opportunity again.',
        'Quantitatively understand the impact of your media in organically driving sales and engagement for external brands.',
        'Combine your customer data with Yobi to discover purchasing intent and dynamically position the best ads based on predicted future behavior.',
      ],
    },
    {
      title: 'Recommendations',
      features: [
        'Optimize engagement and revenue by expertly personalizing individual experiences on a site or app.',
        'Connect your first party customer data with Yobi to create complete customer journeys and understand intent in real time.',
        'Strategically organize and prioritize content that maximizes engagement potential.',
      ],
    },
    {
      title: 'Behavior Predictions',
      features: [
        'Control your future. Uncover the drivers of growth and hidden behavioral trends for your business with Yobi’s predictive models.',
        'Discover external factors that impact revenue and engagement.',
        'Safely prepare for any disruption in behavior throughout the marketplace.',
      ],
    },
  ],
};

const Personalization = () => (
  <MainLayout pageMetadata={SeoMetadata.personalization}>
    <RequestDemoHero title={pageTitle} description={pageDescription} />
    <Features />
    <Profile />
    <UseCases {...useCases} />
  </MainLayout>
);

export default Personalization;
